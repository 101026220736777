"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));

var embedlyTagIncluded = false;
var _default = {
  props: ['url', 'component', 'componentProps', 'isIframe', 'showPin'],
  data: function data() {
    return {};
  },
  computed: {
    embedlyKey: function embedlyKey() {
      return this.$state.settings.embedly.key;
    }
  },
  watch: {
    url: function url() {
      this.updateEmbed();
    },
    isIframe: function isIframe() {
      this.updateEmbed();
    }
  },
  created: function created() {
    this.updateEmbed();
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.$state.$emit('mediaviewer.opened');
    });
  },
  methods: {
    updateEmbed: function updateEmbed() {
      var _this2 = this;

      if (!this.url || this.isIframe || this.component) {
        // return if embedly script is not needed
        return;
      }

      var checkEmbedlyAndShowCard = function checkEmbedlyAndShowCard() {
        // If the embedly function doesn't exist it's probably still loading
        // the embedly script
        if (typeof window.embedly !== 'function') {
          (0, _setTimeout2.default)(checkEmbedlyAndShowCard, 100);
          return;
        }

        _this2.$nextTick(function () {
          window.embedly('card', _this2.$refs.embedlyLink);
        });
      };

      if (!embedlyTagIncluded) {
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//cdn.embedly.com/widgets/platform.js';
        head.appendChild(script);
        embedlyTagIncluded = true;
      }

      checkEmbedlyAndShowCard();
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["MediaViewer"]
window._kiwi_exports.components.MediaViewer = exports.default ? exports.default : exports;
